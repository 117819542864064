// router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import HelloWorld from '@/components/HelloWorld';
import ManualEditor from '@/components/ManualEditor';
import UserManual from '@/components/UserManual.vue';
import AdminManual from '@/components/AdminManual.vue';
import UserNotes from '@/components/UserNotes.vue';
import UserCalendar from '@/components/UserCalendar.vue';
import FlashcardView from '@/components/Flashcard.vue'; // Keep the import path
import EditFlashcard from '@/components/EditFlashcard.vue';
import CSVManager from '@/components/CSVManager.vue';
import Chatbot from "@/components/Chatbot.vue";


const routes = [
  {
    path: '/',
    name: 'HelloWorld',
    component: HelloWorld
  },
 {
    path: '/show-flashcards',
    name: 'FlashcardView', // Change the route name to match the new component name
    component: FlashcardView
  },
  {
    path: '/edit-flashcard',
    name: 'EditFlashcard', // Change the route name to match the new component name
    component: EditFlashcard
  },
    {
    path: '/csv-manager',
    name: 'CSVManager', // Change the route name to match the new component name
    component: CSVManager
  },
    {
    path: '/chatbot',
    name: 'Chatbot', // Change the route name to match the new component name
    component: Chatbot
  },
  {
    path: '/edit-manual',
    name: 'ManualEditor',
    component: ManualEditor
  },
  {
  path: '/edit-user-manual',
  name: 'UserManual',
  component: UserManual, // your user manual component
  meta: { requiresAuth: true } // Remove the role: 'user' part
  },

  {
    path: '/edit-admin-manual',
    name: 'AdminManual',
    component: AdminManual, // your admin manual component
    meta: { requiresAuth: true, role: 'admin' }
  },
  {
    path: '/user-notes',
    name: 'UserNotes',
    component: UserNotes,
    meta: { requiresAuth: true }
  },
{
    path: '/user-calendar',
    name: 'UserCalendar',
    component: UserCalendar,
    props: route => ({ selectedFont: route.params.selectedFont }),
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;

  if (requiresAuth && !user) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
